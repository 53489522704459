<template>
  <v-chip :color="color" :dark="dark">
    <v-icon left>{{ icon }}</v-icon>
    {{ text }}
  </v-chip>
</template>

<script>
export default {
  props: {
    careType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      types: {
        "Not Sure": {
          icon: "mdi-account-question-outline",
          color: "lightGrey",
          dark: false
        },
        "Night Time Care": {
          icon: "mdi-weather-night",
          color: "purple darken-4",
          dark: true
        },
        "Hourly Care": {
          icon: "mdi-clock-outline",
          color: "oliveGreen lighten-3",
          dark: false
        },
        "Live In Care": {
          icon: "mdi-home-outline",
          color: "red lighten-2",
          dark: false
        }
      }
    }
  },
  computed: {
    text() {
      return this.careType !== "" ? this.careType : "Not Declared"
    },
    icon() {
      return this.types[this.careType]?.icon || "mdi-circle-off-outline"
    },
    color() {
      return this.types[this.careType]?.color || "grey"
    },
    dark() {
      if (this.careType in this.types) {
        return this.types[this.careType].dark
      }
      return true
    }
  }
}
</script>
