import { CompanyService, CompanyUnauthedService } from "@/api/instance"
import { DeAuthSession } from "@/api/de-auth"
import store from "@/store"

export const GetProfile = async () => {
  try {
    let {
      data: { user }
    } = await CompanyService().get("/profile")
    user.company.radius =
      user.company.radius !== null ? user.company.radius : null
    user.company.complete_coverage =
      user.company.complete_coverage != null
        ? user.company.complete_coverage
        : null
    store.commit("set_profile", user)
  } catch (e) {
    DeAuthSession(e)
    throw e
  }
}

export const UpdateProfile = async user => {
  try {
    await CompanyService().put(`/profile`, { user })
    store.commit("set_profile", user)
  } catch (e) {
    DeAuthSession(e)
    throw e
  }
}

export const GeocodeAddress = async address => {
  let { data } = await CompanyUnauthedService.get(`/profile/address/geocode`, {
    params: {
      addr: address
    }
  })

  return data
}
